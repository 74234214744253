import * as React from "react";
import CallToAction from "../components/CallToAction/CallToAction";
import Layout from "../components/Layout/Layout";
import Companies from "../components/Companies/Companies";
import Masthead from "../components/Masthead/Masthead";
import Service from "../components/Service/Service";
import TextMedia from "../components/TextMedia/TextMedia";
import MastImage from "../images/industries/automotive-header.jpg";
import Testimonial from "../components/Testimonial/Testimonial";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

const ctaData = [
  {
    text: "Whether for Service Workshop Bookings, Accident Repairs or organising PDI's, planning workflow is essential to any vehicle workshop. Clear, precise, and simple.",
  },
  {
    text: "Helping you make the right decisions. Identify, review, discuss and act all done by utilising the tools within T Cards Online.",
  },
  {
    text: "Visibility and sharing information, giving you the most up to date information. Helping communication, whether between Parts and the Workshop or sales admin and the prep department.",
  },
  {
    text: "Creating records that can be easily reviewed. Accountability and traceability, leaving no stone unturned.",
  },
];

const quoteData = {
  text: "As a work flow management tool the T Cards Online system is excellent; Clear, precise, simple and VERY adaptable.",
  author: "Duncan Exton, Managing Director, Lovells Accident Repair Centres",
  image: <StaticImage src='../images/industries/pronto-test.jpg' alt='' />,
};

const screensData = [
  {
    title: "Calendar or Custom Boards",
    text: "Quickly create and edit boards using dates or custom column names. Create and name Row Groups for extra versatile planning. Navigate and transfer T Cards from Board to Board.",
    image: (
      <StaticImage
        src='../images/industries/automotive/calendar.jpg'
        alt='Calendar or Custom Boards'
      />
    ),
  },
  {
    title: "Unlimited Card Designs",
    text: "Card Designs can easily be created to suit any application using one of our design templates or simply create your own.",
    image: (
      <StaticImage
        src='../images/new-design.png'
        alt='Unlimited Card Designs'
      />
    ),
  },
  {
    title: "Flexible User Permissions",
    text: "Change permissions to restrict the access or editing rights for all team members. Choose who can edit or view anything from Boards to Row Groups to individual fields.",
    image: (
      <StaticImage
        src='../images/industries/automotive/permissions.jpg'
        alt='Flexible User Permissions'
      />
    ),
  },
  {
    title: "Import Data to Boards",
    text: "Quickly import cards straight to a Board from an Excel spreadsheet. Simply download the Board template, populate the fields and upload.",
    image: (
      <StaticImage src='../../images/import.png' alt='Import Data to Boards' />
    ),
  },
];

const Title = () => {
  return (
    <div className='mb-5'>
      <h2 className='title italic'>
        Proven over the decades as the simplest and most cost-effective way of
        managing Workshops, Parts and Vehicle Stock.
      </h2>
      <p>
        T Card boards and cards have evolved over the years as standard cards
        with generic information, but with T Cards Online you can easily adapt
        and refine what you need.
      </p>
    </div>
  );
};

const AutomotivePage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>T Cards Online | Automotive Planning Tools</title>
      </Helmet>
      <Masthead image={MastImage}>
        <h1>Agile Automotive Planning Tools</h1>
      </Masthead>
      <CallToAction data={ctaData} sectionTitle={<Title />} />
      <Testimonial data={quoteData} />
      <TextMedia data={screensData} />

      <Service />
      <Companies title='Trusted across the industry' logos={data} />
    </Layout>
  );
};

export default AutomotivePage;

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/images/logos/automotive/" }
      }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(height: 115)
          }
        }
      }
    }
  }
`;
