import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "./Testimonial.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const defaultData = {
  text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Dolorum debitis enim magnam id aut eligendi inventore provident unde atque, culpa quod quae ipsum, amet veritatis vero ex. Harum, eius nesciunt.",
  author: "Author, Role, Company",
  image: <StaticImage src='https://placekitten.com/800/600' alt='' />,
};

const Testimonial = ({ data = defaultData }) => {
  return (
    <section className={styles.root}>
      <Container>
        <Row className='align-items-center'>
          <Col lg={7}>
            <div className={styles.quote}>{`\u201C${data.text}\u201D`}</div>
            <div className={styles.author}>{data.author}</div>
          </Col>
          <Col>{data.image}</Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonial;
